button:hover {
    background-color: #3c42b3 !important; /* Darker color on hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    transform: scale(1.05); /* Slightly enlarge the button */
}

button:focus {
    outline: none; /* Remove default focus outline */
}

a {
    color: #3c42b3; /* Blue link color */

    &:hover {
        color: #3c42b3; /* Keep color on hover */
    }
    text-decoration: none; /* Remove underline */
}
