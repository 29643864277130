/* Container styling for the markdown content */
.markdown-content {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    margin: 0 auto;
    padding: 1rem;
}

/* Headings */
.markdown-content h1 {
    font-size: 2rem;
    margin: 1.5rem 0 1rem;
    font-weight: bold;
    border-bottom: 2px solid #ccc;
    padding-bottom: 0.3rem;
}

.markdown-content h2 {
    font-size: 1.6rem;
    margin: 1.5rem 0 1rem;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.7rem;
}

.markdown-content h3 {
    font-size: 1.4rem;
    margin: 1rem 0;
    font-weight: bold;
}

.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
    font-size: 1.25rem;
    margin: 0.75rem 0;
    font-weight: bold;
}

/* Paragraphs */
.markdown-content p {
    margin-bottom: 2rem;
    text-align: justify;
       font-size: 1.15rem;
}

/* Lists */

/* Change bullets to solid circles */

/* Change bullets to squares */
.markdown-content ul {
    list-style-type: square;
}

/* Add spacing and alignment if needed */
.markdown-content ul li {
    margin-left: 1rem; /* Adjust indentation */
    margin-bottom: 1rem; /* Spacing between items */
     font-size: 1.15rem;
}


.markdown-content ul,
.markdown-content ol {
    margin: 1rem 0 1.5rem 0rem;
    padding-left: 0rem;
}

.markdown-content ul li,
.markdown-content ol li {
    margin-bottom: 0.5rem;
}

/* Nested lists */
.markdown-content ul ul,
.markdown-content ol ol {
    margin: 0.5rem 0 1rem 1.5rem;
}

/* Blockquotes */
.markdown-content blockquote {
    border-left: 4px solid #ccc;
    padding-left: 1rem;
    margin: 1rem 0;
    font-style: italic;
    color: #555;
}

/* Code blocks */
.markdown-content pre {
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 5px;
    overflow-x: auto;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
}

.markdown-content code {
    background-color: #f5f5f5;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    color: #d63384;
}

/* Links */
.markdown-content a {
    color: #007bff;
    text-decoration: none;
}

.markdown-content a:hover {
    text-decoration: underline;
}

/* Tables */
.markdown-content table {
    border-collapse: collapse;
    width: 100%;
    margin: 1.5rem 0;
    font-size: 15px;
}

.markdown-content table th,
.markdown-content table td {
    border: 1px solid #ccc;
    padding: 0.75rem;
    text-align: left;
}

.markdown-content table th {
    background-color: #f5f5f5;
    font-weight: bold;
}

/* Horizontal Rule */
.markdown-content hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1.5rem 0;
}

/* Images */
.markdown-content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1.5rem 0;
}

/* Inline elements */
.markdown-content em {
    font-style: italic;
}

.markdown-content strong {
    font-weight: bold;
}
